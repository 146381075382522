.background-loading{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 6000;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.85);
}
.container-loading{
    position: absolute;
    //margin: 0px;
    margin: 0px 0px 0px -50px;
    padding: 0px;
    top: 40%;
    left: 49%;
    z-index: 6500;
    //transform: translate(-50%, -50%);
    
}
.loading{
  font:26px/1.5 Monospace;
  color:#FFE05D;
  perspective:100px;
  >span{
    animation:flip 2s infinite;
    display: inline-block;
    transform-origin:50% 50% -10px;
    transform-style: preserve-3d;
    @for $i from 1 to 9{
      &:nth-child(#{$i}){
        animation-delay:$i/10+s;
      }
    }
  }
}
@keyframes flip{
  to{
    transform: rotateX(1turn);
  }
}

