.filters{
    &_container{
        position: absolute;        
        right: 0px;
        z-index: 300;
        transition: transform 0.3s ease; // Agregamos una transición para el desplazamiento suave
    }
}

.collapsed {
    transform: translateX(calc(100% - 33px)); // Mueve el contenedor a la izquierda dejando 33px visibles
  }

.ññ{
    color: #37bee023;
}

.ll{
    color: rgba(255, 0, 0, 0.733);
}