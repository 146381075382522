.user_form_section{
    &_container{
        padding-top: 0px;
        padding-bottom: 15px;
    }

    &_list{
       display: flex;
        margin: 0px 0px;
        p{
            margin: 0px;
            padding: 3px;
        }  
    }
   
}