.login{
    &_container{
        padding: 10px ;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 2px 2px 2px rgba(37, 37, 37, 0.705);
        border-radius: 5px;
        position: relative;
        width: 400px;
        height: 250px;        
        img{
            width: 330px;
            padding-top: 45px;
        }
    }
    &_row_logo{
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }   
    &_row_form{
        height: 80%;
        width: 200px;
        display:flex ;
        justify-content: center;
        align-items: center;
        z-index: 100;
        .ant-input{
            width: 250px ;
            border-radius: 0px;
            height: 40px ;
        }
        .ant-input-affix-wrapper{
            width: 250px !important;
            border-radius: 0px !important;
            height: 40px !important;
            .ant-input{
                width: 95%;
                height: 30px ;
            }
        }
        .ant-btn-primary{
            width: 250px;
            background-color: #121b44;
            border: none;
            border-radius: 0px;
            height: 40px;
            &:hover{
                background-color: #0200668e
            }
        }
    }
    &_background::before{   
        backdrop-filter: blur(5px);
    }
    &_background{
      //  display: none;
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 1rem 0  rgba(0, 0, 0, .2); 
        border-radius: 15px;
        background-color: rgba(255, 255, 255, 0.219); 
    }
}