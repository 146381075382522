.movements_cash_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        } 
        h3{
            color: white;
        }      
    } 
    &_btn{
        margin-right: 5px;                      
    }
    // .anticon svg {
    //     color: #37bde0 !important;
    //     &:hover{
    //         color: #71e3ff !important     
    //     }
    // }
    .ant-table-cell{
        padding: 10px !important;
    } 
    
}

.check-box-group{
    label{        
        width: 200px;
        color: #75799c;
    }
  
    P{
        color: white;
    }
  
}

.class_false{
    color: #37bde0
}




