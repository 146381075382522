.profile_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        }    
        h4{
            font-size: 15px;
        }
    } 
    &_data{
        h4{
            color:#6b6f92;//rgb(91, 132, 165);
            font-size: 15px;
        }
        p{
            color: white;
            margin: 18.62px 20px;
            padding: 0px;
        }  
    }
    
}


