.movements_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        } 
            
    } 
    &_btn{
        margin-right: 5px;                      
    }
    &_icon{
        .anticon svg {
            color: #37bde0 !important;
            &:hover{
                color: #71e3ff !important     
            }
        }
    }
    
    // .ant-table-cell{
    //     padding: 10px !important;
        
    // } 
    .ant-table-cell{
        padding: 7px !important;
        
    } 
    // .ant-table-cell{
    //     padding: 10px !important;
        
    // } 
    .ant-table-thead > tr > th {
        font-size: 12px; /* Ajusta el tamaño del texto */
        padding: 6px; /* Ajusta el espacio interno */
        line-height: 1; /* Reduce la altura de línea */
        border-right: 1px solid rgba(128, 128, 128, 0.445); // Cambia el grosor y color del borde
        border-top: 1px solid gray;
        //background-color: #f5f5f5; 
        text-align: center;  
      }
    
}

.check-box-group{
    label{        
        width: 200px;
        color: #75799c;
    }  
    P{
        color: white;
    }  
}




.background_cell_debit{ 
    background-color: hsla(243, 50%, 58%, 0.11);
}
.cell_debit{
    color: orange
 }

.cell_credit{
   color: #30cb85   
}

.background_cell_credit{
    background-color: hsla(192, 73%, 55%, 0.11)
}


 .cell_window{
    color: yellow;
   // color: #dfdf16;
 }

 .cell_deleted td {
    color: rgb(114, 114, 114) !important;
    //opacity: 0.6 !important;
}
 
.cell_border_left{
    border-left: 1px solid #1B2138;
} 

.cell_border_right{
    border-right: 1px solid #1B2138;
} 

