.approve_btn{
    color:orange;
    padding: 0px;
    margin: 0px;
    &:hover{
        font-weight: 600;
        text-decoration: underline;
    }
}

.pending{
    color: #ADADAD;
    padding: 0px;
    margin: 0px;
}

.loading_btn{
    color:gray;
    padding: 0px;
    margin: 0px;     
}