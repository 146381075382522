.movements-box{
    &_title{
        color: white;
        margin-left: 22px;
        height: 22px;
       
    }
    .span{
        color: white !important;
    }
    &_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ant-collapse{
        margin-top: 40px;
        border: none;
        .ant-collapse-content{
            border-top: none;// 1px solid rgba(55, 190, 224, 0.44);
            background-color: transparent;
        }

    }
    .ant-collapse-content-box{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: transparent;   
        border-bottom: 1px solid rgba(55, 190, 224, 0.44);  

    }

    .ant-collapse-item{
        border-bottom: none;
    }
    .ant-collapse-item .ant-collapse-header{
        .ant-collapse-expand-icon{
            color: rgba(55, 190, 224);
        }
        .ant-collapse-header-text{
            color: #FFFFFFA6;
            &:hover{
                color: white;
            }

        }  
    }

    .ant-collapse>.ant-collapse-item:last-child{
        border-radius: 0px;
    }
   
}

//rgba(55, 190, 224, 0.44);