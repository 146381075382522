.navbar{
    &_container{       
        z-index: 10;
        top: 0;
        margin-bottom: 5px;
        height: 47px;
        width: 100vw;
        background-color: #121832;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 2px 5px 0px #000000;
        cursor: pointer;
        img{
            margin-left: 13px;
            width: 220px;
        }
        h4{
            color: white;
            
        }
    }
    &_profile{
        display: flex;
        margin-right: 70px;
    }
}