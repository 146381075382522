.calance_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        }       
    } 
    .anticon svg {
        color: #37bde0 !important;
        &:hover{
            color: #71e3ff !important     
        }
    }
    .ant-table-cell{
        padding: 10px !important;
    } 
    
}

// .cursor-pointer{
//     cursor: pointer;
//     background-color: #1B2138;
//     // color: white;
//     // //border-left: 1px solid white;
//     // &:hover{
//     //     color: black;
//     // }
// }
