.extraction_deposit_banc_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        } 
        h3{
            color: white;
        }      
    } 
    &_btn{
        margin-right: 5px;     
        //background: linear-gradient(to bottom, #37bde0, #2a8ca5); /*  2a8ca5*/
    //border: 2px solid #37bde0;
    // padding: 10px 20px;
    // font-size: 16px;
    // border-radius: 5px;
    // position: relative;
    // top: -2px;
    // left: -2px;
    // transition: top 0.1s, left 0.1s;
                 
    }
    .anticon svg {
        color: #37bde0 !important;
        &:hover{
            color: #71e3ff !important     
        }
    }
    .ant-table-cell{
        padding: 10px !important;
    } 
    
}

.check-box-group{
    label{        
        width: 200px;
        color: #75799c;
    }
  
    P{
        color: white;
    }
  
}

