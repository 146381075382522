.menu{
    &_container{
       // height: 100%;
       //margin-top: 10px;
       .ant-menu-dark.ant-menu-root.ant-menu-vertical{
            background-color: #121832;
            height: 100%;
         
        }
        .ant-menu-inline{
            background-color:   #121832;
            height: 100%;
            z-index: 200;
        }
        .ant-menu-item-selected{
            background: linear-gradient(to right, #37bde0, #655fca) !important//  #0072ce;
        }
        .ant-btn-primary{
            background: linear-gradient(to right, #37bde0, #655fca) !important//  #0072ce;
            // &:hover{
            //     background-color: #5e90fa !important;
            // }
        }
        .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected{  
            background:  linear-gradient(to right, #37bde0, #655fca) !important// #0072ce !important;
        }
        .ant-menu-submenu-open.ant-menu-submenu-selected.ant-menu-submenu-active{
            background: linear-gradient(to right, #37bde0, #655fca) !important// #0072ce !important;
        }
        
    }
    &_horizontal{
        position: absolute;
        margin-bottom: 16px;
        z-index: 10;
        top: 0;
        height: 70px;
        width: 100vw;
        background-color: #121832;
        border-radius: 5px;
    }

    
}






