.change_password_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        }       
    } 
    // .anticon svg {
    //     color: #37bde0 !important;
    //     &:hover{
    //         color: #71e3ff !important     
    //     }
    // }
    .ant-table-cell{
        padding: 10px !important;
    } 
    
}

.change_password_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        }  
        label{
            color: rgb(167, 167, 167) !important;
        }     
    } 
    &_body{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 90%;
        margin-top: 20px;
        border: 1px solid  #467ef85e; 
        padding: 70px 30px 30px;       
        label{
            color: #9094bd !important;
        }
    }
    &_list{
        display: flex;
        color: white;
        margin: 0px 0px;
    }
    
}


