.dashboard{
    &_container{
        background-color: #1B2138;        
        height: 100vh;           
        width: 100vw;     
    }
    &_body{
        display: flex;
        flex-direction: row;   
        overflow-y: hidden;    
    }
}
