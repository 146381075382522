.sell_and_buy_divide{
    padding-top: 15px;
}

.section_buy{
   padding:  0px 15px 10px;
   margin-top: 5px;
   background-color:  rgb(241, 241, 241);
   border-radius: 5px;
   &_title{
    text-align: center;
    background-color: green;
    width: 120px;
    padding: 1px 3px;
    color: white;
    border-radius: 5px;
    font-weight: 500;
    margin-bottom: 15px;
}
   //background-color: rgba(255, 0, 0, 0.301);
}

.section_sell{
    padding:  0px 15px 10px;
    margin-top: 5px;
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    &_title{
        text-align: center;
        background-color: red;
        width: 100px;
        padding: 1px 3px;
        color: white;
        border-radius: 5px;
        font-weight: 500;
        margin-bottom: 15px;
    }
}

