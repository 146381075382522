.accessPages{
    &-container{
        display: flex;
        flex-direction: row;
        overflow: hidden;
        height: 100vh;   
        width: 100vw;          
    }
    &_login{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;   
        width: 100%; 
    }
 
}

