.dashboard-box{
    overflow-y: auto;
    P{
        color: white;
        margin-left: 22px;
        height: 22px;
    }
    &_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
}