.currency_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        }       
    } 

    .ant-table-cell{
        padding: 10px !important;
    } 
    
}

